// https://github.com/kenwheeler/slick

import $ from 'jquery';
import 'slick-carousel/slick/slick'
import 'slick-carousel/slick/slick.css'

/****** SLIDERS *******/
const sliders = {
    gallery: $('.gallery-slider')
}

sliders.gallery.slick({
    dots: true,
    autoplay: false,
    autoplay: true,
    customPaging: function(slider, i) {
        let thumb = $(slider.$slides[i]).find('.single-slide').data('thumb');
        return `<a href="#galeria"><div class="dot" style="background-image: url(${thumb})"></div></a>`;
    },
    appendDots: $('.gallery-slider-pagination')
});