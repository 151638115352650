import SmoothScroll from 'smooth-scroll';

let scroll = new SmoothScroll('a[href*="#"]');

(function($){
    $(document).ready(function(){

	});

   	$(window).load(function(){
 
		/****** BODY PADDING TOP *******/
		// (function(){

		// 	function setBodyPaddingTop() {
		// 		$('body').css('padding-top', $('.site-header').outerHeight());
		// 	}
		// 	setBodyPaddingTop();
		// 	$(window).resize(setBodyPaddingTop);
		 
		// })();
		
	});
	   
})(jQuery);