//js
require('./js/slider')
require('./js/vendor/jquery.scrollto');
require('./js/helper');
require('./js/navigation');
require('./js/maps');
require('./js/main');

//fonts
// require('./fonts/font/stylesheet.css');

// css
import normalizecss from 'normalize.css';
require('./sass/style.scss');